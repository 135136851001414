var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supplier-ifram" },
    [
      _c("div", { staticClass: "store-head" }, [
        _c("img", { attrs: { src: _vm.supplierInfo.headPic, alt: "" } }),
        _c("span", [_vm._v(_vm._s(_vm.supplierInfo.userNickname))]),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { color: "#000000" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "商品", name: "goods" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    "element-loading-text": "拼命加载中",
                    data: _vm.goods,
                    border: "",
                    "header-row-style": {
                      "font-weight": 100,
                      color: "#0D0202",
                    },
                    "row-style": { "font-size": "14px", color: "#0D0202" },
                    "header-cell-style": {
                      "font-weight": "bold",
                      background: "#F0F2F8",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderNo",
                      label: "商品信息",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v("商品ID: "),
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "bold" } },
                                [_vm._v(_vm._s(row.id))]
                              ),
                            ]),
                            _c("div", [
                              _vm._v("商品名: "),
                              _c(
                                "span",
                                { staticStyle: { color: "#666666" } },
                                [_vm._v(_vm._s(row.name))]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "库存", "max-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [_vm._v(" " + _vm._s(row.stockQuantity) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", "max-width": "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(_vm.classifyStatus(row))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", "max-width": "680" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendGoodsCard(row)
                                  },
                                },
                              },
                              [_vm._v(" 发送 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "订单", name: "order" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    "element-loading-text": "拼命加载中",
                    data: _vm.orders,
                    border: "",
                    "header-row-style": {
                      "font-weight": 100,
                      color: "#0D0202",
                    },
                    "row-style": { "font-size": "14px", color: "#0D0202" },
                    "header-cell-style": {
                      "font-weight": "bold",
                      background: "#F0F2F8",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderNo",
                      label: "订单信息",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v(
                                "订单ID: " + _vm._s(row.tradeOrderVo.orderId)
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "订单创建时间: " +
                                  _vm._s(row.tradeOrderVo.createTime)
                              ),
                            ]),
                            _c("div", [
                              _vm._v("支付金额: "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "font-weight": "bold",
                                  },
                                },
                                [_vm._v("￥" + _vm._s(row.tradeOrderVo.paymet))]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "orderNo", label: "", "min-width": "260" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("div", [
                              _vm._v(
                                "用户:" + _vm._s(row.tradeOrderVo.nickname)
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "手机号:" + _vm._s(row.tradeOrderVo.mobile)
                              ),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(" 商品信息： ")]
                            ),
                            _vm._l(
                              row.tradeOrderGoodsVoList,
                              function (goodsItem, goodsIndex) {
                                return _c("div", { key: goodsIndex }, [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999999" } },
                                    [
                                      _vm._v(
                                        _vm._s(goodsItem.goodsName) +
                                          " | " +
                                          _vm._s(goodsItem.skuName) +
                                          " "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            "￥" + _vm._s(goodsItem.payment)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-weight": "bold",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.orderStautsMsg(
                                        +row.tradeOrderVo.orderStatus
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendOrderCard(row)
                                  },
                                },
                              },
                              [_vm._v(" 发送 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "page-controller" }, [
        _vm.currentPage !== 1
          ? _c(
              "div",
              {
                class: ["btn"],
                on: {
                  click: function ($event) {
                    return _vm.prePage()
                  },
                },
              },
              [_vm._v(" 上一页 ")]
            )
          : _vm._e(),
        !_vm.finished
          ? _c(
              "div",
              {
                class: ["btn"],
                on: {
                  click: function ($event) {
                    return _vm.nextPage()
                  },
                },
              },
              [_vm._v(" 下一页 ")]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }