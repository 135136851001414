<template>
  <div class="supplier-ifram">
    <div class="store-head">
      <img
        :src="supplierInfo.headPic"
        alt=""
      />
      <span>{{ supplierInfo.userNickname }}</span>
    </div>
    <el-tabs
      v-model="activeName"
      color="#000000"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="商品"
        name="goods"
      >
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="goods"
          border
          :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
          :row-style="{'font-size': '14px', 'color': '#0D0202'}"
          :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
        >
          <el-table-column
            prop="orderNo"
            label="商品信息"
            min-width="180"
          >
            <template slot-scope="{ row }">
              <div>商品ID: <span style="font-weight: bold">{{ row.id }}</span></div>
              <div>商品名: <span style="color: #666666">{{ row.name }}</span></div>
            </template>
          </el-table-column>

          <el-table-column
            label="库存"
            max-width="80"
          >
            <template slot-scope="{ row}">
              {{ row.stockQuantity }}
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            max-width="60"
          >
            <template slot-scope="{ row}">
              <span style="color: red">{{ classifyStatus(row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            max-width="680"
          >
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="primary"
                @click="sendGoodsCard(row)"
              >
                发送
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane
        label="订单"
        name="order"
      >
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="orders"
          border
          :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
          :row-style="{'font-size': '14px', 'color': '#0D0202'}"
          :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
        >
          <el-table-column
            prop="orderNo"
            label="订单信息"
            min-width="180"
          >
            <template slot-scope="{ row }">
              <div>订单ID: {{ row.tradeOrderVo.orderId }}</div>
              <!--              <div>订单编号: {{row.tradeOrderVo.orderNo}}</div>-->
              <div>订单创建时间: {{ row.tradeOrderVo.createTime }}</div>
              <div>支付金额: <span style="color: red; font-weight: bold;">￥{{ row.tradeOrderVo.paymet }}</span></div>
            </template>
          </el-table-column>

          <el-table-column
            prop="orderNo"
            label=""
            min-width="260"
          >
            <template slot-scope="{ row }">
              <div>用户:{{ row.tradeOrderVo.nickname }}</div>
              <div>手机号:{{ row.tradeOrderVo.mobile }}</div>
              <div style="font-weight: bold">
                商品信息：
              </div>
              <div
                v-for="(goodsItem, goodsIndex) in row.tradeOrderGoodsVoList"
                :key="goodsIndex"
              >
                <span style="color: #999999">{{ goodsItem.goodsName }} | {{ goodsItem.skuName }} <span
                  style="color: red"
                >￥{{ goodsItem.payment }}</span></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <div style="color: red; font-weight: bold;">
                {{ orderStautsMsg(+row.tradeOrderVo.orderStatus) }}
              </div>
              <el-button
                size="mini"
                type="primary"
                @click="sendOrderCard(row)"
              >
                发送
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      </el-tab-pane>
    </el-tabs>
    <div class="page-controller">
      <div
        v-if="currentPage !== 1"
        :class="['btn']"
        @click="prePage()"
      >
        上一页
      </div>
      <div
        v-if="!finished"
        :class="['btn']"
        @click="nextPage()"
      >
        下一页
      </div>
    </div>
  </div>
</template>
<script>
import { getQueryString } from '@/utils/utils';
import { projectConfigDetail } from '@/utils/projectConfig';

export default {
  data() {
    return {
      activeName: 'goods',
      currentPage: 1,
      pageSize: 8,
      goods: [],
      loading: false,
      orders: [],
      finished: false,
      h5Url: projectConfigDetail.h5Url,
    };
  },
  computed: {
    supplierInfo() {
      return JSON.parse(localStorage.userInfo || null);
    },
    uid() {
      return getQueryString('uid');
    },
    cid() {
      return getQueryString('cid');
    },
    userId() {
      return getQueryString('partnerId');
    },
  },
  mounted() {
    this.getStoreGoodsInfo();
  },
  methods: {
    handleClick(tab, event) {
      this.currentPage = 1;
      this.finished = false;
      this.loading = false;
      if (tab.name === 'order' && this.orders.length === 0) {
        this.orders = [];
        this.getUserInfo();
      }
      if (tab.name === 'goods') {
        this.goods = [];
        this.getStoreGoodsInfo();
      }
    },

    prePage() {
      this.loading = true;
      this.finished = false;
      this.currentPage--;
      if (this.activeName === 'goods') {
        this.getStoreGoodsInfo();
      }
      if (this.activeName === 'order') {
        this.getUserInfo();
      }
    },
    nextPage() {
      this.loading = true;
      this.currentPage++;
      if (this.activeName === 'goods') {
        this.getStoreGoodsInfo();
      }
      if (this.activeName === 'order') {
        this.getUserInfo();
      }
    },
    // 商品状态
    classifyStatus(goods) {
      // 分类状态 (1上架，2售罄，3下架，4审核中，5审核失败 )
      let _classifyStatus = +goods.classifyStatus;
      let statusMsgArr = ['上架', '售罄', '下架', '审核中', '审核失败'];
      return statusMsgArr[_classifyStatus - 1];
    },
    orderStautsMsg(orderType) {
      // 1待支付 2待确认 3待成团 4待发货 5待使用 6待收货 7交易成功 8交易关闭
      let msgs = ['待支付', '待确认', '待成团', '待发货', '待使用', '待收货', '交易成功', '交易关闭'];
      return msgs[orderType - 1];
    },
    sendOrderCard(order) {
      let ZCGoodsBody = order.tradeOrderGoodsVoList.map((i) => {
        return {
          name: i.goodsName,
          pictureUrl: i.goodsImage,
        };
      });
      let ZCOrderBody = {
        orderStatus: 0,
        statusCustom: this.orderStautsMsg(order.tradeOrderVo.orderStatus),
        createTime: order.tradeOrderVo.createTime,
        orderCode: order.tradeOrderVo.orderId,
        orderUrl: `${this.h5Url}/order/detail?orderid=${order.tradeOrderVo.orderId}`,
        totalFee: parseFloat(order.tradeOrderVo.paymet) * 100,
        goodsCount: order.tradeOrderGoodsVoList.length,
        goods: ZCGoodsBody,
      };
      window.parent.postMessage({
        cid: this.cid,
        uid: this.uid,
        msgType: 25, // 固定值
        miniPage: JSON.stringify(ZCOrderBody),
      }, '*');
    },
    sendGoodsCard(goods) {
      let params = {
        title: goods.name,
        url: `https://wsnb-test.hzanchu.com/goodsdetail?id=${goods.id}`,
        description: goods.tagName,
        label: `￥${goods.sellPrice}`,
        thumbnail: goods.imageUrls,
      };
      window.parent.postMessage({
        cid: this.cid,
        uid: this.uid,
        msgType: 24, // 固定值
        miniPage: JSON.stringify(params),
      }, '*');
    },
    // 获取用户订单信息
    getUserInfo() {
      this.$axios.post(this.$api.appSupplierStore.storeOrderData, {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        storeId: this.supplierInfo.storeList[0].storeId,
        userId: this.userId,
        supplierOrderStatus: 0,
      }).then((res) => {
        this.loading = false;
        if (res.code !== 0) return false;
        if (res.data.records.length === 0) {
          this.finished = true;
        }
        this.orders = res.data.records;
        this.$forceUpdate();
      });
    },
    getStoreGoodsInfo() {
      this.$axios.post(this.$api.supplierInfoManage.storeGoods, {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        status: 1,
        storeId: this.supplierInfo.storeList[0].storeId,
      }).then((res) => {
        this.loading = false;
        if (res.code !== 0) return false;
        if (res.data.records.length === 0) {
          this.finished = true;
        }
        this.goods = res.data.records;
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-ifram {
  margin: 24px;
  position: relative;

  .store-head {
    img {
      width: 48px;
      height: 48px;
      display: inline-block;
      position: relative;
      border-radius: 50%;
      border: 5px solid #fff;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, .2);
    }
  }

  .page-controller {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);

    .btn {
      display: inline-block;
      margin: 24px;
      cursor: pointer;
      color: #FFFFFF;
      background: #0D0202;
      padding: 6px 12px;
      border-radius: 6px;
    }
  }
}
</style>
